import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicantService } from '../applicant/applicant.service';
import { CommentModule } from '@aifs-shared/comments/comment.module';
import { IssueDetailModalComponent } from './issue-detail-modal.component';
import { NewIssueModalComponent } from './new-issue-modal.component';
import { MetaFormModule } from '@aifs-shared/ngx-metaform/metaform.module';

@NgModule({
    declarations: [
        IssueDetailModalComponent,
        NewIssueModalComponent
    ],
    imports: [
        CommonModule,
        CommentModule,
        MetaFormModule,
    ],
    exports: [
        IssueDetailModalComponent
    ],
    providers: [
        ApplicantService
    ]
})
export class IssueModule { }
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DefaultRequestOptions } from '@aifs-shared/net/default-request-options.service';

import { UploadRoutingModule } from './uploads.routing';

// NgBootstrap
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PipesModule } from '@aifs-shared/pipe/pipes.module';
import { UploadModule } from '@aifs-shared/upload/upload.module';
import { ModalsModule } from '@aifs-shared/modals/modals.module';

import { UploadsService } from './uploads.service';
import { ReferenceService } from '../reference/reference.service';

@NgModule({
    // Inputs
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgbModule,
        PipesModule,
        UploadRoutingModule,
        UploadModule,
        ModalsModule

    ],
    // Outputs
    declarations: [
    ],
    // Services
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: DefaultRequestOptions, multi: true },
        UploadsService,
        ReferenceService,
        NgbActiveModal
    ]

})
export class UploadsModule { }

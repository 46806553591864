import { PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { RoleName } from '@aifs-shared/user/user';
import { UserService } from '@aifs-shared/user/user.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { AgencyInterviewerDetailsResponse, AgentInterviewerDetails, InterviewerService } from './interviewer/interviewer.service';

@Component({
    selector: 'app-dashboard-main',
    templateUrl: './dashboard-main.component.html',
    styleUrls: ['./dashboard-main.component.scss']
})

export class DashboardMainComponent implements OnInit {
    loginDisplay = false;
    loggedIn = false;
    userRole = '';
    isAgent = false;
    isInterviewer = false;

    constructor(
        private title: Title,
        private msalAuthService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private portalUserService: PortalUserService,
        private interviewerService: InterviewerService
    ) { }

    ngOnInit(): void {
        this.title.setTitle('Camp America Management Portal');

        this.msalBroadcastService.msalSubject$
            .subscribe((result: EventMessage) => {
                if (result.eventType == EventType.ACQUIRE_TOKEN_SUCCESS) {
                    this.loggedIn = this.msalAuthService.instance.getActiveAccount() != null;
                }
                this.getPermissions();
                this.getInterviewerDetails();
            });

        this.loggedIn = this.msalAuthService.instance.getActiveAccount() != null;
        this.getPermissions();
        this.getInterviewerDetails();
    }

    /**
     * @note - IAS - not final; we want to get specific roles from the
     * database in addition to large-scale details like 'interviewer' or
     * 'agent'
     */
    getPermissions() {
        if (this.loggedIn) {
            this.isInterviewerOrAbove = this.portalUserService.isInRoleOrAbove(RoleName.Interviewer);
            if (this.portalUserService.hasInterviewerRole()) {
                this.userRole = 'INT';
                this.isInterviewer = true;
                this.selectedInterviewerId = this.portalUserService.getInterviewerId();
            }
        }
    }

    getInterviewerDetails(): void {
        if (this.selectedInterviewerId) {
            this.interviewerService
                .getInterviewerDetails(this.selectedInterviewerId)
                .subscribe({
                    next: (result: AgencyInterviewerDetailsResponse) => {
                        this.interviewer = result?.details;
                        this.isOnlineInterviewer = result?.details!.isOnlineInterviewer;
                        this.getOverdueReports();
                    },
                    error: (error) => {
                        console.error(error);
                    }
                });
        }
    }

    getOverdueReports(): void {
        this.interviewerService
            .getOverdueReportCount(this.selectedInterviewerId)
            .subscribe({
                next: (count: number) => {
                    this.overdueLimitReached = (count > this.interviewer!.overdueReportLimit);
                }
            })
    }

    interviewer?: AgentInterviewerDetails;
    overdueLimitReached = false;

    isInterviewerOrAbove: boolean = false;
    selectedInterviewerId: number = 0;    
    isOnlineInterviewer: boolean = true;
}

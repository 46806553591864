import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferenceDetailComponent } from './reference-detail.component';
import { ReferenceDetailModalComponent } from './reference-detail-modal.component';
import { LookupService } from '@aifs-shared/lookup/lookup.service';
import { CommentModule } from '@aifs-shared/comments/comment.module';
import { ReferenceOptionsComponent } from './reference-options.component';
import { MetaFormModule } from '@aifs-metaform/public-api';
import { UploadService } from '@aifs-shared/upload/upload.service';
import { PipesModule } from '@aifs-shared/pipe/pipes.module';
import { SpinnerModule } from '@aifs-shared/spinner/spinner.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DefaultRequestOptions } from '@aifs-shared/net/default-request-options.service';
import { AuthenticateRefereeComponent } from './authenticate-referee.component';
import { RefereeOptionsComponent } from './referee-options.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { UpdateEmailComponent } from './update-email.component';
import { ReferenceActionsComponent } from './reference-actions.component';
import { AddNewReferenceComponent } from './add-new-reference.component';
import { UploadModule } from '@aifs-shared/upload/upload.module';

@NgModule({
    declarations: [
        ReferenceDetailComponent,
        ReferenceDetailModalComponent,
        RefereeOptionsComponent,
        ReferenceOptionsComponent,
        AuthenticateRefereeComponent,
        ReferenceOptionsComponent,
        UpdateEmailComponent,
        ReferenceActionsComponent,
        AddNewReferenceComponent
    ],
    imports: [
        CommonModule,
        CommentModule,
        NgbPopoverModule,
        MetaFormModule,
        PipesModule,
        SpinnerModule,
        UploadModule,
    ],
    exports: [
        ReferenceDetailComponent,
        ReferenceDetailModalComponent,
        ReferenceOptionsComponent,
        RefereeOptionsComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: DefaultRequestOptions, multi: true },
        LookupService,
        UploadService
    ]
})
export class ReferenceModule { }

<div class="page-content">
    <div  *ngIf="loginDone">
        <div class="top">
            <app-top-menu></app-top-menu>
        </div>
        <div class="page-main-content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div *ngIf="!loginDone" class="load_container">
        <img src="img/CA_RGB_Stack_Alternate_300px.png" class="toplogo">
        <p>Verifying your login data...</p>
        <img src="img/blu-loader.gif" class="bl">
    </div>
</div>


import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { RoleName } from '@aifs-shared/user/user';
import { DashboardMainComponent } from './dashboard-main.component';
import { authGuard } from '@aifs-shared/auth/guard.fn';

const routes: Routes = [
    { path: 'applicant', loadChildren: () => import('./applicant/applicant.module').then(m => m.ApplicantModule), canActivate: [authGuard] },
    {
        path: 'interviewer',
        loadChildren: () => import('./interviewer/interviewer.module').then(m => m.InterviewerModule),
        canActivate: [authGuard],
        data: {
            minimumRole: RoleName.Interviewer
        }
    },
    {
        path: 'staff',
        loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule),
        canActivate: [authGuard],
        data: {
            minimumRole: RoleName.Staff
        }
    },
    { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
    { path: 'messages', loadChildren: () => import('./shared/message/message.module').then(m => m.MessageModule), canActivate: [authGuard] },
    { path: 'maintenance', loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule), canActivate: [authGuard] },
    { path: '', component: DashboardMainComponent }
];
const isIframe = window !== window.parent && !window.opener;
const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled'
    // scrollOffset: [0, 64],
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }


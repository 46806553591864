import { AuthenticationService } from '@aifs-shared/auth/authentication.service';
import { AlertModal } from '@aifs-shared/modals';
import { User, UserDataResponse } from '@aifs-shared/user/user';
import { UserService } from '@aifs-shared/user/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Inject, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, PopupRequest, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, Subscription, filter, takeUntil } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    // MARK: Show MSAL Debug
    showMSALDebugInfo = false;

    constructor(
        @Inject(MSAL_GUARD_CONFIG) 
        private msalGuardConfig: MsalGuardConfiguration,
        private msalAuthService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private userService: UserService,
        private authService: AuthenticationService,
        private modalService: NgbModal,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.returnUrl = '/';
    }

    ngOnInit(): void {
        this.userChangeSubscription = this.userService.userChanged.subscribe({
            next: (u: User | undefined) => {
                if (u) console.log(`Subscription Changed: ${u.id}`);
                // else console.log(`User cleared`);

                this.user = u;
            }
        });

        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'
        if (this.returnUrl.toLowerCase().startsWith('http')) {
            // Do not permit open redirects (IAS)
            this.returnUrl = '/';
        }

        this.msalAuthService.handleRedirectObservable().subscribe();
        this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

        this.setLoginDisplay();

        // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED 
        // events emitted when a user logs in or out of another tab or window
        this.msalAuthService.instance.enableAccountStorageEvents();
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
            )
            .subscribe({
                next: (result: EventMessage) => {
                    if (this.msalAuthService.instance.getAllAccounts().length === 0) {
                        window.location.pathname = "/";
                    } else {
                        // this.setLoginDisplay();
                        this.initialiseAndCheckAccount();
                    }
                }
            });

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                if(this.showMSALDebugInfo) console.log(`inProgress?`)
                // this.setLoginDisplay();
                // this.checkAndSetActiveAccount();
                this.initialiseAndCheckAccount();
            })
    }

    initialiseAndCheckAccount(): void {
        this.msalAuthService.initialize().subscribe({
            complete: () => {
                if (this.showMSALDebugInfo) console.log('initialise completed');
                this.syncAzureLoginWithCALogin();
            }
        });
    }

    syncAzureLoginWithCALogin() {
        //if not logged in azure

        //only @aifs.co.uk accounts
        const azureLoggedIn = this.msalAuthService.instance.getAllAccounts().filter(el => el.username.toUpperCase().substring(el.username.length - 11, el.username.length) == '@AIFS.CO.UK').length > 0;
        let activeAzureAccount = this.msalAuthService.instance.getActiveAccount();
        if(this.showMSALDebugInfo) console.info(activeAzureAccount);
        // console.log(this.msalAuthService.instance.getAllAccounts());
        //

        if (azureLoggedIn && activeAzureAccount == null) {
            this.msalAuthService.instance.setActiveAccount(this.msalAuthService.instance.getAllAccounts()[0])
        }
        activeAzureAccount = this.msalAuthService.instance.getActiveAccount();
        // console.log(activeAzureAccount);

        if (!azureLoggedIn && activeAzureAccount == null) {
            if (!this.waitTimeDone) {
                // console.log('waiting...');
                new Promise(vars => setTimeout(vars, 2000))
                    .then(() => {
                        this.waitTimeDone = true;
                        this.syncAzureLoginWithCALogin();
                    })
            }
            else {
                // console.log('aint no more waiting');
                this.msalAuthService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest)
            }
            return;
        }

        //if logged in but we haven't logged him into CA yet, or we have a wrong user logged in
        const activeCAUser = this.userService.user();
        if (activeAzureAccount != null
            && azureLoggedIn
            && (activeCAUser == null)
        ) {
            this.loginDone = false;
            this.msalAuthService.acquireTokenSilent({} as SilentRequest)
                .subscribe({
                    next: (response: AuthenticationResult) => {
                    this.msalAuthService.instance.setActiveAccount(response.account);
                    this.signInAzureUser(response.accessToken);
                },
                error: (error: any) => {
                    this.msalAuthService.acquireTokenRedirect({} as SilentRequest);
                }});
            return;
        }

        this.loginDone = true;
    }

    // logout() {
    //     localStorage.clear();
    //     this.authService.logout();
    //     this.msalAuthService.logout();
    // }

    signInAzureUser(accessToken: string) {
        // console.log('signInAzureUser', accessToken);
        this.userService
            .loginAzure(accessToken)
            .subscribe({
                next: (success: UserDataResponse) => {
                    // console.debug(`got azure user: ${JSON.stringify(success)}`);
                    this.sendingRequest = false;
                    this.loginDone = true;
                    const user = this.userService.user();
                    if (!user) throw Error(`Did not receive a valid user object!`);

                    if (success.error) {
                        const msg = success.message ? success.message : 'OOS-001';
                        this.showModal("Sign In", `The server returned an error attempting to sign you in.<br><br>Please contact support with the following message:<br><br>${msg}`);                    
                    } else {
                        // console.log(`Got valid Azure signin`);
                        const redirect = sessionStorage.getItem('redirectUrl');
                        if (redirect) {
                            // console.log(`Got a redirect: ${redirect}`);
                            this.router.navigateByUrl(redirect);
                        }
                    }
                },
                error: (error: any) => {
                    // something unexpected occured
                    let msg = error;
                    this.emailAssigned = false;
                    this.sendingRequest = false;
                    this.loginDone = true;
                    if (error instanceof HttpErrorResponse) {
                        // you could extract more info about the error if you want, e.g.:
                        msg = `${error.statusText} (${error.status})`;
                        if (error.status !== 0) {
                            // errMsg = ...
                            this.showModal("Sign In", `The server returned an error attempting to sign you in.<br><br>Please contact support with the following message:<br><br>${msg}`)
                                .subscribe({
                                    next: (result: any) => {
                                        this.router.navigateByUrl('user/user-email-not-assigned');
                                        return;
                                    }
                                });
                        }
                    }
                    this.router.navigateByUrl('user/user-email-not-assigned');                        
                }
            });
    }

    ngOnDestroy(): void {
        this.userChangeSubscription?.unsubscribe();

        this._destroying$.next(undefined);
        this._destroying$.complete();
    }


    // MARK: Azure Sign-In Logic

    setLoginDisplay() {
        this.loginDisplay = this.msalAuthService.instance.getAllAccounts().length > 0;
    }

    // checkAndSetActiveAccount() {
    //     console.log(`checkAndSetActiveAccount`);

    //     /**
    //      * If no active account set but there are accounts signed in, sets first account to active account
    //      * To use active account set here, subscribe to inProgress$ first in your component
    //      * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
    //      */
    //     let activeAccount = this.msalAuthService.instance.getActiveAccount();

    //     //only @aifs.co.uk accounts
    //     const azureLoggedIn = this.msalAuthService.instance.getAllAccounts().filter(el => el.username.toUpperCase().substring(el.username.length - 11, el.username.length) == '@AIFS.CO.UK').length > 0;

    //     if (!activeAccount && this.msalAuthService.instance.getAllAccounts().length > 0) {
    //         let accounts = this.msalAuthService.instance.getAllAccounts();
    //         this.msalAuthService.instance.setActiveAccount(accounts[0]);

    //     }
    //     // if logged in but we haven't logged him into CA yet, or we 
    //     // have a wrong user logged in
    //     const activeCAUser = this.userService.user();
    //     console.log(`Active User: ${activeAccount}, ${azureLoggedIn}, ${activeCAUser}`);
    //     if (activeAccount != null
    //         && azureLoggedIn
    //         && (activeCAUser == null)) {
    //         console.log(`Got an active account, and azure is logged in`);
    //         if (!activeCAUser) {
    //             console.log(`Don't have an active CA user`);
    //             this.loginDone = false;

    //             this.msalAuthService.acquireTokenSilent({} as SilentRequest)
    //                 .subscribe({
    //                     next: (response: AuthenticationResult) => {
    //                         console.log(`Token ACQ`);
    //                         this.msalAuthService.instance.setActiveAccount(response.account);
    //                         this.signInAzureUser(response.accessToken);
    //                     },
    //                     error: (error: any) => {
    //                         this.msalAuthService.acquireTokenRedirect({} as SilentRequest);
    //                     }
    //                 });
    //             return;
    //         }
    //     }
    //     console.log(`Have an active CA user`);
    //     this.loginDone = true;
    //     this.user = this.userService.user();
    //     console.log(`User is ${this.user?.firstName}`);
    // }

    // loginRedirect() {
    //     console.log(`loginRedirect`);
    //     if (this.msalGuardConfig.authRequest) {
    //         this.msalAuthService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    //     } else {
    //         this.msalAuthService.loginRedirect();
    //     }
    // }

    // loginPopup() {
    //     console.log(`loginPopup`);
    //     if (this.msalGuardConfig.authRequest) {
    //         this.msalAuthService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
    //             .subscribe((response: AuthenticationResult) => {
    //                 this.msalAuthService.instance.setActiveAccount(response.account);
    //             });
    //     } else {
    //         this.msalAuthService.loginPopup()
    //             .subscribe((response: AuthenticationResult) => {
    //                 this.msalAuthService.instance.setActiveAccount(response.account);
    //             });
    //     }
    // }

    // xxx_signInAzureUser(accessToken: string) {
    //     console.log(`signInAzureUser`);
    //     this.userService
    //         .loginAzure(accessToken)
    //         .subscribe({
    //             next: (success: UserDataResponse) => {
    //                 console.debug(`got azure user: ${JSON.stringify(success)}`);
    //                 this.sendingRequest = false;
    //                 this.loginDone = true;
    //                 const user = this.userService.user();
    //                 if (!user) throw Error(`Did not receive a valid user object!`);
    //             },
    //             error: (error: any) => {
    //                 console.error(error);

    //                 // something unexpected occured
    //                 this.emailAssigned = false;
    //                 this.sendingRequest = false;
    //                 this.router.navigateByUrl('user/user-email-not-assigned');
    //                 //this.loginDone = true;
    //             }
    //         });
    // }

    logout(popup?: boolean) {
        console.log(`logout`);
        localStorage.clear();
        this.authService.logout();
        this.userService.userHasSignedOut();

        if (popup) {
            this.msalAuthService.logoutPopup({
                mainWindowRedirectUri: "/"
            });
        } else {
            this.msalAuthService.logoutRedirect();
        }
    }


    private showModal(title: string, message: string): Observable<any> {
        const s = new Subject<any>();

        const modalRef = this.modalService.open(AlertModal);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.body = message;

        modalRef.result.then(
            result => {
                if (result) {
                    s.next(result);
                }
            }).catch((err: any) => { });


        return s;
    }

    // MARK: Variables

    title = 'Abeona';
    isIframe = false;
    loginDisplay = false;
    loginDone = false;
    emailAssigned = false;
    sendingRequest = false;
    waitTimeDone = false;
    returnUrl = '/';
    userChangeSubscription?: Subscription;

    user: User | undefined;

    private readonly _destroying$ = new Subject<void>();
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LookupService } from '@aifs-shared/lookup/lookup.service';
import { CommentModule } from '@aifs-shared/comments/comment.module';
import { UploadService } from '@aifs-shared/upload/upload.service';
import { PipesModule } from '@aifs-shared/pipe/pipes.module';
import { SpinnerModule } from '@aifs-shared/spinner/spinner.module';
import { DefaultRequestOptions } from '@aifs-shared/net/default-request-options.service';
import { DBSDetailModalComponent } from './dbs-detail-modal.component';
import { UploadModule } from '@aifs-shared/upload/upload.module';
import { PoliceCheckService } from '@aifs-shared/policeCheck/police-check.service';

@NgModule({
    declarations: [
        DBSDetailModalComponent,
    ],
    imports: [
        CommonModule,
        CommentModule,
        PipesModule,
        SpinnerModule,
        UploadModule
    ],
    exports: [
        DBSDetailModalComponent,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: DefaultRequestOptions, multi: true },
        LookupService,
        UploadService,
        PoliceCheckService
    ]
})
export class DBSModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { BaseService } from '@aifs-shared/common/base-service';
import { BaseResponse, ResponseResultCode } from '@aifs-shared/common/base-response';
import { AuthenticationService } from '@aifs-shared/auth/authentication.service';


@Injectable({
    providedIn: 'root'
})
export class FeatureService extends BaseService {

    constructor(
        private authService: AuthenticationService,
        // private pushService: PushService,
        private http: HttpClient) {
        super();

        // // Get an observable for events emitted on this channel
        // this.pushService.sub('admin').subscribe(
        //     (x: ChannelEvent) => {
        //         // console.debug(`----->>>> Event on 'admin' channel: ${JSON.stringify(x, null, 2)}`);

        //         if (x.Data.State === 'FeatureStatusUpdate') {
        //             // console.log(`Feature Status Update`);
        //             this.getFeatures()
        //                 .subscribe(
        //                     // eslint-disable-next-line @typescript-eslint/no-unused-vars
        //                     f => {
        //                         // console.log(`Updated features: ${f}`, f);
        //                         this.authService.requestRefreshOfStatus();
        //                     }
        //                 );

        //         }
        //     },
        //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
        //     (error: any) => {
        //         console.error("Attempt to join channel failed!", error);
        //     }
        // )

        this.getFeatures();
    }

    getFeatures(): Observable<boolean> {
        const subject = new Subject<boolean>()
        // console.log(`Reload User Data: User is signed in? ${this.authService.isLoggedIn}`);
        this.http
            .get<BaseResponse>(environment.ServiceUrl_Feature_GetStatus)
            .subscribe(
                data => {
                    // console.info(`RELOAD USER DATA:`, data.result);
                    const response: BaseResponse = data;
                    switch (response.resultCode) {
                        case ResponseResultCode.Success:
                            this.features = this.getResultData<EnabledFeatureResponse>(response).features;
                            this.hasLoaded = true;
                            subject.next(true);
                            subject.complete();
                            break;
                        case ResponseResultCode.Error:
                            subject.error({ error: response.error })
                            break;
                    }
                },
                error => {
                    subject.error(error)
                }
            )

        return subject
    }

    areFeaturesLoaded(): boolean {
        return this.hasLoaded;
    }

    /// Is the desired feature available?
    isFeatureEnabled(type: FeatureType): boolean {
        let enabled = false;

        if (!this.features) {
            console.warn(`Feature: ${type} was not found`);
            return true;
        }

        const feature = this.features.find(({ id }) => id === type);
        if (feature) {
            // console.log(`Found feature '${feature.name}' = ${feature.enabled}`);
            enabled = feature.enabled;
        }

        return enabled;
    }

    features?: EnabledFeature[];
    hasLoaded = false;
}

export class EnabledFeatureResponse {
    features?: EnabledFeature[];
}

export class EnabledFeature {
    categoryId!: FeatureCategoryType;
    categoryName!: string;

    id!: FeatureType;
    name!: string;
    enabled!: boolean;
}

export enum FeatureCategoryType {
    SiteAccess = 1,
    ExternalLinks,
    ApplicantMenu
}

export enum FeatureType {
    SignIn = 1,
    Registration,
    PreviousSeasonAllowed,
    ClosedForMaintenance,
    ParticipantZone,
    SecureTrading,
    SendApplicationsToORCA,
    Placement,
    Payments,
    SelfProposal,
    Flights,
    ReturnFlights,
    PreviousSeasonReturnFlights,
    Visa,
    Insurance,
    ReadySetGo,
    AergoSystemAvailable,
    Uploads,
    ORCAServicesAvailable,
    Orientation
}

import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { DocumentType } from '@aifs-shared/upload/document-type'

// import { ReviewUploadsComponent } from './review-uploads.component'
// import { UploadsListComponent } from './list-uploads.component'
// import { SelectSkillComponent } from './select-skill.component'
// import { AddUploadComponent } from './add-upload.component'
// import { UploadDetailsComponent } from './upload-details.component'

const routes: Routes = [
    // { path: '', component: ReviewUploadsComponent },
    // { path: 'references/add', component: AddUploadComponent, data: { documentType: DocumentType.Reference, previousRoute: '/references/review', nextRoute: '/references/review', displayTitle: false } },
    // { path: 'medical-form/add', component: AddUploadComponent, data: { documentType: DocumentType.MedicalForm, previousRoute: '/disclosure', nextRoute: '/disclosure', displayTitle: false } },
    // { path: 'view/:id', component: UploadDetailsComponent }
]

@NgModule({
    imports: [
        RouterModule.forChild(
            routes
        )
    ],
    exports: [
        RouterModule
    ]
})
export class UploadRoutingModule { }
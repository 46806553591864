import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToDoService } from './todo.service';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
    ],
    providers: [
        ToDoService
    ],
    exports: [
    ]
})
export class ToDoModule { }

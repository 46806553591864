import { Injectable } from '@angular/core';
import { PushService, ChannelEvent, ConnectionState } from '@aifs-shared/push/push.service';
import { AuthenticationService } from '@aifs-shared/auth/authentication.service';
import { UserService } from '@aifs-shared/user/user.service';
import { map } from "rxjs/operators";
import { User } from './user';
import { Observable, throwError, Subject } from 'rxjs';

@Injectable()
export class UserPushService {

    public constructor(
        private pushService: PushService,
        private authService: AuthenticationService,
        private userService: UserService
    ) { // console.debug("UserPushService::()"); 
    }

    public registerForUser(user: User): void {
        // console.debug(`UserPush: Register for User`);

        if (!this.connectionState$) {
            // console.info(`No connection yet, initialising`);
            this.initialiseConnection();
        }

        const u = user;
        let channel = `tasks+${u.id}`;

        if (!this.currentUser || this.currentUser.id !== user.id) {
            this.currentUser = user;
            channel = `tasks+${user.id}`;
            // console.log(`UserPush: current user subscription changing, subbing to channel ${channel}`);
        } else {
            // console.info(`Already on channel ${channel} for user ${this.currentUser.id}`);
            return;
        }

        // console.info(`Registering interest in push channel '${channel}'`);

        // Get an observable for events emitted on this channel
        this.pushService
            .sub(channel)
            .subscribe({
                next: (x: ChannelEvent) => {
                    console.debug(`----->>>> Event on '${channel}' channel: ${JSON.stringify(x, null, 2)}`);

                    // if (x.Data.State == 'UserAgreedTermsAndConditions') {
                    //     // console.debug(`${channel}: State returned is UserAgreedTermsAndConditions, updating tracker data`);
                    //     // This reloads user data as well.
                    //     // this.tracker.reloadForCurrentUser();
                    // }

                    // if (x.Data.State === 'ApplicantCreatedApplication' || x.Data.State === 'NewIssueRaised' || x.Data.Starting === 'ApplicationUpdated') {
                    //     // console.debug(`${channel}: State returned is ${x.Data.State}, updating data`);
                    //     this.userService.reloadUserData()
                    //         .subscribe(
                    //             () => {
                    //                 // console.log(`UPS: Reloaded user service. Checking application`);
                                
                    //             }
                    //         )

                    //     if (x.Data.State === 'ApplicantCreatedApplication') {
                    //         this.pushAlert.next(
                    //             {
                    //                 data: x.Data.State,
                    //                 extra: x.Data.Extra
                    //             }
                    //         );
                    //     }
                    // }

                    // if (x.Data.State == 'InterviewerSetInterviewDate') {
                    //     // console.log(`${channel}: Interviewer has created an interview for us!`);
                    //     // This reloads user data as well.
                    //     // this.userService.reloadUserData()
                    //     //     .subscribe(
                    //     //     data => {
                    //     //         // console.log(`UPS: Reloaded user service. Checking application`, x.Data);
                    //     //         this.tracker.reloadForCurrentUser();

                    //     this.pushAlert.next(
                    //         {
                    //             title: "Interview Request Completed",
                    //             text: "Your interviewer has created an interview for you. Click to confirm.",
                    //             redirect: `/interview/${x.Data.Extra}`
                    //         }
                    //     );
                    // })
                    // }
                },
                error: (error: unknown) => {
                    console.error("Attempt to join channel failed!", error);
                }
            });
    }

    public unregisterUser(u: User) {
        this.closeConnection(u);
    }

    initialiseConnection() {
        // console.debug(`#1`);

        if (!this.pushService.connectionState$) {
            this.pushService.start();
        }
        if (this.pushService.connectionState$) {
            this.connectionState$ = this.pushService.connectionState$
                .pipe(
                    map((state: ConnectionState) => { return ConnectionState[state]; })
                );
        }

        // console.debug(`#2`);
        if (this.pushService.error$) {
            this.pushService.error$.subscribe(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (error: any) => { console.warn(error); },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (error: any) => { console.error("errors$ error", error); }
            );
        }
        // console.debug(`#3`);
        // Wire up a handler for the starting$ observable to log the
        // success/fail result
        if (this.pushService.starting$) {
            this.pushService.starting$.subscribe(
                () => {
                    //console.debug("signalr service has been started");
                },
                () => {
                    //console.warn("signalr service failed to start!");
                }
            );
        }

        //console.debug("Starting the channel service");
        this.pushService.start();
    }

    closeConnection(u: User) {
        // console.warn(`NOTE: We should be unsubscribing from the user channel now.`);
        // TODO(Ian): I'm pretty sure we'd want to stop subscribing to
        // the specified channel once the user has signed-out, although
        // the current code we're using for the push service doesn't
        // illustrate such a thing, and I'm not at the point where I have
        // time to wade through the SignalR documentation. Please feel
        // free to do so
        const channel = `tasks+${u.id}`;

        // console.info(`I want to unsubscribe from channel ${channel}`);
        this.pushService.unsub(channel);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError(error: any) {
        // In a real world app, we might use a remote logging infrastructure
        const errMsg = error.message ? error.message : error.toString();
        console.error(errMsg);
        return throwError(errMsg);
    }

    // An internal "copy" of the connection state stream used because
    //  we want to map the values of the original stream. If we didn't 
    //  need to do that then we could use the service's observable 
    connectionState$?: Observable<string>;
    currentUser?: User;

     // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public pushAlert: Subject<any> = new Subject<any>();
}
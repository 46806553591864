import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';

import { UserModule } from './user/user.module';
import { AuthenticationModule } from '@aifs-shared/auth/authentication.module';
import { AuthenticationService } from '@aifs-shared/auth/authentication.service';
import { ModalsModule } from '@aifs-shared/modals/modals.module';
import { PipesModule } from '@aifs-shared/pipe/pipes.module';
import { FeatureService } from '@aifs-shared/feature/feature.service';
import { UserService } from '@aifs-shared/user/user.service';
import { UserFormService } from '@aifs-shared/forms/user-form.service';
import { SpinnerModule } from '@aifs-shared/spinner/spinner.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardMainComponent } from './dashboard-main.component';
import { DefaultRequestOptions } from '@aifs-shared/net/default-request-options.service';
import { TopMenuModule } from '@aifs-shared/menu/top-menu.module';
import { CacheModule } from '@aifs-shared/cache/cache.module';
import { CacheService } from '@aifs-shared/cache/cache.service';
import { BookmarkModule } from './bookmark/bookmark.module';
import { ViewHistoryModule } from './viewHistory/viewHistory.module';
import { BookmarkService } from './bookmark/bookmark.service';
import { IssueModule } from './issue/issue.module';
import { ReferenceModule } from './reference/reference.module';
import { MessageService } from '@aifs-shared/message/message.service';
import { UploadModule } from '@aifs-shared/upload/upload.module';
import { ToDoModule } from '@aifs-shared/todo/todo.module';
import { UploadsModule } from './uploads/uploads.module';
import { UserPushService } from '@aifs-shared/user/user-push.service';
import { SearchModule } from '@aifs-shared/search/search.module';
import { ViewHistoryService } from './viewHistory/viewHistory.service';
import { PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { environment } from '@environments/environment';
import { MessageModule } from '@aifs-shared/message/message.module';
import { InterviewerModule } from './interviewer/interviewer.module';
import { DBSModule } from './dbs/dbs.module';
import { InsightsModule } from './insights/insights.module';
import { JWT_OPTIONS, JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { ApplicantModule } from './applicant/applicant.module';
import { StaffModule } from './staff/staff.module';
import { MapService } from '@aifs-shared/map/map.service';
import { BusinessRuleService } from '@aifs-shared/ngx-metaform/business-rule.service';
import { MetaFormModule } from '@aifs-shared/ngx-metaform/metaform.module';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

// MARK: Show MSAL Debug
const showMSALDebugInfo = false;

// MARK: MSAL callbacks
export function loggerCallback(logLevel: LogLevel, message: string) {
    if (logLevel == LogLevel.Error) { console.error(message); return }
    if (logLevel == LogLevel.Warning) { console.warn(message); return }
    if(showMSALDebugInfo && !environment.production) console.debug(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    const clientId = environment.authClientId;
    const authority = environment.authority;
    const scopes = environment.authScopes;
    // console.log(`Using client: ${clientId}, authority: ${authority}`);

    return new PublicClientApplication({
        auth: {
            // clientId: 'c0f505fa-f274-461a-a1a0-5b3427d60606', // This is your client ID
            // authority: '06509efb-06dd-44bb-a7aa-5983e544eb44', // This is your tenant ID
            // redirectUri: 'http://localhost:4201',// This is your redirect URI
            // clientId: 'c0f505fa-f274-461a-a1a0-5b3427d60606', // PPE testing environment
            // authority: 'https://login.microsoftonline.com/common', // Prod environment. Uncomment to use.
            // authority: 'https://login.microsoftonline.com/aifs.co.uk', // PPE testing environment.
            // postLogoutRedirectUri: '/'
            clientId: clientId,
            authority: authority,
            redirectUri: '/',
            postLogoutRedirectUri: '/'
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
            }
        }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    const resourceMap = environment.protectedResourceMap;
    // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
    //protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);
    protectedResourceMap.set(resourceMap, ['user.read']);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: [...environment.authScopes]
        },
        loginFailedRoute: '/login-failed'
    };
}

@NgModule({
    declarations: [
        AppComponent,
        DashboardMainComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MsalModule,
        PipesModule,
        NgbModule,
        AuthenticationModule,
        BookmarkModule,
        ViewHistoryModule,
        CacheModule,
        DBSModule,
        IssueModule,
        MetaFormModule,
        ModalsModule,
        MessageModule,
        PipesModule,
        ReferenceModule,
        SearchModule,
        SpinnerModule,
        ToDoModule,
        TopMenuModule,
        UploadModule,
        UploadsModule,
        UserModule,
        ApplicantModule,
        InterviewerModule,
        InsightsModule,
        StaffModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DefaultRequestOptions,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        {
          provide: JWT_OPTIONS,
          useClass: JwtModule
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        Title,
        // AuthGuard,
        // AgentAuthGuard,
        // StaffAuthGuard,
        AuthenticationService,
        FeatureService,
        UserService,
        BusinessRuleService,
        UserFormService,
        BookmarkService,
        ViewHistoryService,
        CacheService,
        MessageService,
        UserPushService,
        PortalUserService,
        JwtHelperService,
        MapService,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

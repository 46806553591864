import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from '../shared/common/base-service';
import { BaseResponse } from '@aifs-shared/common/base-response';
import { ResponseData } from '@aifs-shared/common/response-data';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class UploadsService extends BaseService {

    constructor(http: HttpClient) {
        super();

        this.presigningRequired.set(UploadDocumentType.Portrait, false);
        this.presigningRequired.set(UploadDocumentType.Activity, false);
        this.presigningRequired.set(UploadDocumentType.Reference, true);
        this.presigningRequired.set(UploadDocumentType.Certificate, true);
        this.presigningRequired.set(UploadDocumentType.InterviewerPortrait, false);
        this.presigningRequired.set(UploadDocumentType.Video, false);
        this.presigningRequired.set(UploadDocumentType.MedicalForm, true);
        this.presigningRequired.set(UploadDocumentType.CDFairPlacementSheet, false);
        this.presigningRequired.set(UploadDocumentType.InsurancePolicy, true);
        this.presigningRequired.set(UploadDocumentType.Miscellaneous, true);
        this.presigningRequired.set(UploadDocumentType.PoliceCheck, true);
    }

    requiresPresignedUrl(documentType: UploadDocumentType, url: string): boolean {
        return (!this.presigningRequired.get(documentType));
    }

    // getPresignedUrl(url: string): Observable<PresignedUrl> {
    //     const s = new Subject<PresignedUrl>();

    //     this.http
    //         .get<BaseResponse>()

    //     return s;
    // }

    presigningRequired: Map<UploadDocumentType, boolean> = new Map<UploadDocumentType, boolean>();
}

export enum UploadDocumentType {
    Unspecified = 0,
    Portrait = 1,
    Activity = 2,
    Reference = 3,
    Certificate = 4,
    InterviewerPortrait = 51,
    Video = 99,
    MedicalForm = 100,
    CDFairPlacementSheet = 101,
    InsurancePolicy = 102,
    Miscellaneous = 103,
    PoliceCheck = 104
}

export class PresignedUrlResponse implements ResponseData { 
    details!: PresignedUrl;
}

export interface PresignedUrl {
    url: string;
}